exports.components = {
  "component---src-pages-d-[id]-tsx": () => import("./../../../src/pages/d/[id].tsx" /* webpackChunkName: "component---src-pages-d-[id]-tsx" */),
  "component---src-pages-drafts-tsx": () => import("./../../../src/pages/drafts.tsx" /* webpackChunkName: "component---src-pages-drafts-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor/[...].tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-popular-tsx": () => import("./../../../src/pages/popular.tsx" /* webpackChunkName: "component---src-pages-popular-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-search-[query]-tsx": () => import("./../../../src/pages/search/[query].tsx" /* webpackChunkName: "component---src-pages-search-[query]-tsx" */),
  "component---src-pages-writer-[id]-tsx": () => import("./../../../src/pages/writer/[id].tsx" /* webpackChunkName: "component---src-pages-writer-[id]-tsx" */),
  "component---src-pages-writers-tsx": () => import("./../../../src/pages/writers.tsx" /* webpackChunkName: "component---src-pages-writers-tsx" */)
}

